<template>
  <div class="row" style="background:#ededee; border-radius: 10px; box-shadow: 0.5px 0.866px 4px 0px rgba(2, 8, 2, 0.2); padding-bottom: 25px;">
    <div class="col-md-12 col-sm-12 mt-1">
      <div v-if="roleType === 'student'" class="row mt-2"><!-- Klasik Öğrenci -->
        <br/>
        <div class="col-md-6 mt-3" v-if="Number(this.grade) >= 5 && Number(this.grade) <= 7"> <!-- 5,6,7. sınıflar Hexagonia --->
          <!-- <span>Hexagonia</span> -->
          <a :href="`https://www.hexagonia.com.tr${hexaquerystingtext}`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
            </div>
          </a>
        </div>
        <div class="col-md-6 mt-3" v-if="Number(this.grade) >= 1 && Number(this.grade) <= 4">  <!-- 1,2,3,4. sınıflar Kidzwonder --->
          <!-- <span>Kidzwonder</span> -->
            <a :href="`https://www.kidzwonder.com${kwquerystingtext}`" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonder]"></div>
              </div>
            </a>
        </div>

        <template v-if="this.rlpCode != null && this.rlpCode != '###'">
          <div class="col-md-6 mt-3">
            <a data-toggle="modal" data-target="#rlpDescription">             
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
              </div>
            </a>
          </div>
        </template>
        <template v-else-if="this.rlpCode == '###'">
          <div class="col-md-6 mt-3">
            <a data-toggle="modal" data-target="#rlpDescription">
            <!-- <a @click="RLP()" target="_blank"> -->
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
              </div>
            </a>
          </div>
        </template>
      <!-- <div class="col-md-6 mt-1">
          <a :href="`https://www.myeduassessment.com/#/login${querystingtext}`" target="_blank">
            <div :class="$style.divPlatform">
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnUesAssessment]"></div>
            </div>
          </a>
      </div> -->
      </div>
      <div v-else-if="roleType === 'teacher'" class="row mt-2"> <!-- Klasik Öğretmen Bölümü -->

        <div class="col-md-6" v-if="this.rlpUse != null">
        <a :href="`https://richmondlp.com/login`" target="blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
            </div>
          </a>
      </div>

        <div class="col-md-6">
          <!-- <span>Hexagonia</span> -->
          <a :href="`https://www.uesportals.com/#/login/signin${querystingtext}`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
            </div>
          </a>
        </div>
        <div class="col-md-6">
          <!-- <span>Kidzwonder</span> -->
            <a :href="`https://www.uesportals.com/#/login/signin${kwquerystingtext}`" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonder]"></div>
              </div>
            </a>
        </div>

        <div class="col-md-6 mt-3">
          <a :href="`https://drive.google.com/drive/folders/1Mfg-4F718VRJbmlVy2FzFVxL2XmN6HhW?usp=sharing`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="$style.txtPlatform">READERS</div>
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnReaders]"></div>
            </div>
          </a>
        </div>

      </div>
    </div>

    <div class="modal fade" id="rlpDescription" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle1" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle1">Richmond Learning Platform Aktivasyon Adımları</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              
              <div class="col-md-12 mt-1">
                <!-- <a :href="`https://www.kidzwonder.com${this.gndkwdemo1}`" target="_blank"> -->
                  <div :class="$style.divPlatform">
                    <template v-if="this.rlpCode != null && this.rlpCode != '###'">
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">1.</b> Platforma ilk defa erişiyorsanız, öncelikle aşağıdaki <b>"Kodu Kopyala"</b> butonuna basarak kitap kodunuzu kopyalayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">2.</b> Altta gördüğünüz <b>"Richmond Learning Platform</b> simgesine tıklayınız ve siteye geçiş yapınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">3.</b> Ekranda <b>"MY PRODUCTS"</b> başlığı altında bulunan <b>"ADD ACCESS CODE"</b> butonuna basın ve kopyaladığınız kodu <b>"Please Enter a Code"</b> alanına yapıştırınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">4.</b>	Kodu yapıştırdıktan sonra sağ alt bölümde görünen <b>"Add Product"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">5.</b>	Ardından ekranda göreceğiniz <b>"START"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">6.</b>	Artık hesabınızda kitabınız aktif edilmiştir. Platformu kullanmaya başlayabilirsiniz.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">7.</b>	Sonraki erişimlerinizde sadece <b>"Richmond Learning Platform"</b> butonuna basıp platformu kullanabilirsiniz.</p>
                      <!-- <p style="margin: 5px 5px 5px 5px"> <b style="color:red">UYARI :</b> Yukarıdaki adımlar tamamlandıktan sonra bu işlemi bir daha yapmanıza gerek yoktur. 
                        Aşağıda gördüğünüz Richmond Learning Platform simgesine tıklayarak sistemi kullanmaya devam edebilirsiniz.</p> -->
                    </template>
                    <template v-else-if="this.rlpCode == '###'">
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">1.</b> Platforma ilk defa erişiyorsanız altta gördüğünüz <b>"Richmond Learning Platform</b> simgesine tıklayınız ve siteye geçiş yapınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">2.</b> Ekranda <b>"MY PRODUCTS"</b> başlığı altında bulunan <b>"ADD ACCESS CODE"</b> butonuna basın ve kitabınızda ön kapak içinde bulunan kodu <b>"Please Enter a Code"</b> alanına yazınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">3.</b>	Kodu yazdıktan sonra sağ alt bölümde görünen <b>"Add Product"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">4.</b>	Ardından ekranda göreceğiniz <b>"START"</b> butonuna tıklayınız.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">5.</b>	Artık hesabınızda kitabınız aktif edilmiştir. Platformu kullanmaya başlayabilirsiniz.</p>
                      <p style="margin: 5px 5px 5px 5px"><b style="color:red">6.</b>	Sonraki erişimlerinizde sadece <b>"Richmond Learning Platform"</b> butonuna basıp platformu kullanabilirsiniz.</p>
                      <!-- <p style="margin: 5px 5px 5px 5px"> <b style="color:red">UYARI :</b> Yukarıdaki adımlar tamamlandıktan sonra bu işlemi bir daha yapmanıza gerek yoktur. 
                        Aşağıda gördüğünüz Richmond Learning Platform simgesine tıklayarak sistemi kullanmaya devam edebilirsiniz.</p> -->
                    </template>


                  </div>
                <!-- </a> -->
              </div>

              <div class="col-md-12 mt-1" v-if="this.rlpCode != null && this.rlpCode != '###'">
                  <div :class="$style.divPlatform" style="height:auto !important">
                    <div class="row mt-3 mb-3">
                      <div class="col-8">
                        <p style="margin: 5px 5px 5px 5px" ref="code" v-on:focus="$event.target.select()" >{{rlpCode}}</p>
                        <input type="hidden" id="rlp-code" :value="rlpCode">
                      </div>
                      
                      <div class="col-4">                      
                        <button type="button" @click="copyCode()" class="btn btn-primary">Kodu Kopyala</button>
                      </div>
                    </div>                    
                  </div>
              </div>
              <div class="col-md-6 mt-1">
                <!-- <a data-toggle="modal" data-target="#rlpDescription"> -->
                  <a @click="RLP()" target="_blank" data-dismiss="modal">
                  
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLP]"></div>
                    <!-- <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div> -->
                  </div>
                </a>
              </div>

              <div v-if="this.rlpCode != null && this.rlpCode != '###'" class="col-md-6 mt-1">
                <a :href="`https://www.youtube.com/embed/5VS7u30m_Gc?si=2K_RXLa5Y_u_L64y`" target="blank">
                 
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLPYoutube]"></div>
                    <!-- <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div> -->
                  </div>
                </a>
              </div>

              <div v-if="this.rlpCode == '###'" class="col-md-6 mt-1">
                <a :href="`https://www.youtube.com/embed/O2x4o_LeEqY?si=ctl_x10KvOEDzTVe`" target="blank">
                 
                    <div :class="$style.divPlatform">
                    <div :class="[$style.divPlatformBtn, $style.divPlatformBtnRLPYoutube]"></div>
                    <!-- <div :class="$style.txtPlatform">RICHMOND LEARNING PLATFORM</div> -->
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import jwt from 'jsonwebtoken';
import { mapState } from 'vuex';
import services from '@/services/user';
import CryptoJS from 'crypto-js';

import swal from 'sweetalert';

export default {
  name: 'platforms',
  data() {
    return {
      rlpUse: null,
      rlpCode: null,
      grade: '',
      grade_id: null,
      level: '',
      roleType: '',
      querystingtext: '',
      kwquerystingtext: '',
      hexaquerystingtext: '',
      zoom: '',
      checkTime: true,
    };
  },
  computed: {
    ...mapState(['userDetails', 'username', 'password']),
    levelName() {
      return this.$store.state.userDetails.organization.find(x => x.type === 'level').name;
    },
  },
  beforeMount() {
    this.setData();
  },
  watch: {
    userDetails() {
      this.setData();
    },
  },
  methods: {

    copyCode(){
      let testingCodeToCopy = document.querySelector('#rlp-code')
      testingCodeToCopy.setAttribute('type', 'text') 
      testingCodeToCopy.select()

      // try {
        document.execCommand('copy');
        // var successful = document.execCommand('copy');
        // var msg = successful ? 'successful' : 'unsuccessful';
      //   alert('Testing code was copied ' + msg);
      // } catch (err) {
      //   alert('Oops, unable to copy');
      // }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    async timeStamp() {
      const res = await services.getTimeStamp();
      if (this.title === 'demo') return true;
      if (res.data.is_weekend) return false;
      // eslint-disable-next-line
      return (res.data.data.split(' ')[1] > '08:00:00') && (res.data.data.split(' ')[1] < '17:00:00') ? true : false;
    },
    async setData() {
      if (this.userDetails) {
        this.level = this.userDetails.organization.find(x => x.type === 'level').name;
        this.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
        this.main = this.userDetails.organization.find(x => x.type === 'main').id;
        this.zoom = this.userDetails.organization.filter(x => x.type === 'grade');
        this.roleType = this.userDetails.role_type;
        this.title = this.userDetails.title;
        this.rlpUse = this.userDetails.organization.find(x => x.type === 'campus').rlp_institute_code
        this.rlpCode = this.userDetails.rlp_code ? this.userDetails.rlp_code : null
        
        //sadece bu sınıfta kod gelse bile sirek yönlendirme yapılıyor
        if(this.userDetails.role_type =='student'){
          this.grade_id = this.userDetails.organization.find(x => x.type === 'grade').id;
          if (this.grade_id =='49349'){
            this.rlpCode ='###'
          }
        }

        this.querystingtext = `?username=${btoa(this.username)}&password=${btoa(this.password)}`;
        
        if (this.userDetails.lang === 'en') {
          this.hexaquerystingtext = `?username=${btoa('steve.jobs')}&password=${btoa('090807')}`;
        } else {
          this.hexaquerystingtext = this.querystingtext;
        }
        this.checkTime = await this.timeStamp();
      }
      // this.kwquerystingtext = `?username=${btoa('uesnlkw_teacher')}&passwordw=${btoa('123456')}`;
      this.kwquerystingtext = this.querystingtext;
    },
    goTeacherLink(id) {
      this.$router.push({
        name: 'teachersResources',
        params: {
          id,
        },
      });
    },

    RLP() {
      // console.log('sdsds')
      try {
        this.ready = false;
        const secretKey = "VswFYGzh2k45N7Q8R9@TBuCVexFY.G2J3K4N6p7Q%9SATB-VdWeXFZHJ3m5N";
        const userData = {}
        const classData = {}
        if (this.userDetails) {
          userData.school_id =  this.userDetails.organization.find(x => x.type === 'main').id;
          userData.campus_id =  this.userDetails.organization.find(x => x.type === 'campus').id;
          userData.campus_name = this.userDetails.organization.find(x => x.type === 'campus').name;
          userData.first_name = this.userDetails.first_name;
          userData.last_name = this.userDetails.last_name;
          userData.username = this.userDetails.email;
          userData.role_type = this.userDetails.role_type;
          classData.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
          classData.branch = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[1] : '';
          userData.class = [classData];
          // userData.user_id = this.userDetails.user_id;

        }

        const textJson = JSON.stringify(userData);
        const uriText = decodeURIComponent(textJson)
        const ciphertext = CryptoJS.AES.encrypt(uriText, secretKey).toString();
        this.ready = true;
        window.open('https://service.ueslms.com/#/rlp/?sso=' + btoa(ciphertext));
        

      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },
  },
};
</script>

<style src='./style.less' lang='less' module/>

<style scoped>
  a {
    text-decoration: none;
    color: black;
  }
</style>
