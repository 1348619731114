module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#6f2b8f',
    secondaryColor: '#d42e12',
    backgroundColor: 'whitesmoke',
  },
  apiKey: 'M9oa6RN39P4r4E1CQy2h7y2VGhapYKqwxiH0jWIv3N19mjS1',
  // apiKey: 'sJg1CBq2keb19nJOA0CENHkUt1wuHXyJro7Eudix9GwVPsT0', // ! myeduclass apiKey
};
